* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Montserrat", monospace, sans-serif;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-variant-caps: all-small-caps;
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-variant-caps: all-small-caps;
  src: url('fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
       url('fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

h1 {
  font-size: 18em;
}

h2 {
  font-size: 3em;
}

/**
* MEDIA QUERIES
*/

/* xl */
@media (max-width: 1200px) {
  h1 {
    font-size: 16em;
  }
  h2 {
    font-size: 2.5em;
  }
}

/* lg */
@media (max-width: 992px) {
  h1 {
    font-size: 10em;
  }
  h2 {
    font-size: 2em;
  }
}

/* md */
@media (max-width: 768px) {
  h1 {
    font-size: 8em;
  }
  h2 {
    font-size: 1.5em;
  }
}

/* sm */
@media (max-width: 576px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1em;
  }
}
