.nav {
  grid-area: nav;
  top: 40vh;
  position: fixed;
  left: 0;
}

ul {
  row-gap: 15px;
}

.list {
  list-style: none;
}

.list a {
  color: #9a9bad;
  text-decoration: none;
  padding: 8px 16px;;
  display: block;
}

.list a:hover {
  color: #bf00ff;
  
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #bf00ff;
  text-align: center;
  position: absolute;
  left: 0;
  padding: 0 16px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltipicon {
  visibility: visible;
}

.tooltip:hover .tooltipicon {
  visibility: hidden;
}


