.header {
  grid-area: header;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.left,
.right {
  display: grid;
  grid-row: 1 / span 2;
}

.left {
  grid-column: 1;
  justify-items: end;
  color: #000;
  background: #fff;
}

.right {
  grid-column: 2;
  color: #fff;
  background: #000;
}

.title {
  grid-row: 1;
  align-self: end;
  cursor: pointer;
}

.subtitle {
  grid-row: 2;
}

.left .title {
  letter-spacing: -20px;
}

.right .title {
  letter-spacing: 15px;
}

.negative .left {
  color:#fff;
  background-color: #000;
}

.negative .right {
  color: #000;
  background-color: #fff;
}

/**
* MEDIA QUERIES
*/

/* xl */
@media (max-width: 1200px) {
  .left .title {
    letter-spacing: -18px;
  }
  .right .title {
    letter-spacing: 13px;
  }
}

/* lg */
@media (max-width: 992px) {
  .left .title {
    letter-spacing: -11px;
  }
  .right .title {
    letter-spacing: 7px;
  }
}

/* md */
@media (max-width: 768px) {
  .left .title {
    letter-spacing: -9px;
  }
  .right .title {
    letter-spacing: 5px;
  }
}

/* sm */
@media (max-width: 576px) {
  .left .title {
    letter-spacing: -4px;
  }
  .right .title {
    letter-spacing: 1px;
  }
}
