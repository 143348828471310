.container {
  display: grid;
  height: 100vh;
  grid-template-areas:
    "header"
    "about"
    "skills"
    "projects"
    "contact"
    "footer";
}
